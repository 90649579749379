<template>
  <div class="main">
    <!-- <div class="itemName">
      <img src="@/assets/personCenter/resumeManagement/Rectangle.png" alt="" />
      <span>简历投递</span>
    </div> -->

    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>简历投递</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <el-table
        ref="multipleTable"
        stripe
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :row-key="record=>record.id"
        @selection-change="handleSelectionChange"
      >
      <!-- @row-click="toDatum" -->
        <!-- <el-table-column align="center" type="selection" min-width="5%">
        </el-table-column> -->

        <el-table-column
          align="center"
          prop="orgName"
          label="公司名称"
          min-width="25%"
        >
         <template slot-scope="scope">
          <div @click="toDatumOrg(scope.row)" style="color:#1890FF;">{{ scope.row.orgName ? scope.row.orgName : '' }}</div>
        </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="postName"
          label="职位名称"
          min-width="25%"
        >
         <template slot-scope="scope">
          <div @click="toDatum(scope.row)" style="color:#1890FF;">{{ scope.row.postName ? scope.row.postName : '' }}</div>
        </template>
        </el-table-column>

        <el-table-column align="center" prop="pay" label="薪资" min-width="20%">
          <template slot-scope="scope">
            <span>{{
              scope.row.pay  ?  getPay(scope.row.pay)  : ''   
            }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          align="center"
          prop="create_time"
          label="投递时间"
          min-width="25%"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.create_time.substring(
                0,
                scope.row.create_time.indexOf('.')
              )
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
        align="center"
        prop="remind"
        label="关注提醒"
        min-width="10%"
      >
        <template slot-scope="scope">
          <el-checkbox @change="isRemind(scope.row)" :value="scope.row.remind"
            >提醒</el-checkbox
          >
        </template>
      </el-table-column> -->
        <!-- <el-table-column
        align="center"
        prop="remarks"
        label="备注"
        min-width="8%"
      >
        <i class="el-icon-edit" style="color: orange; font-size: 18px"></i>
      </el-table-column> -->
        <!-- <el-table-column align="center" label="操作" min-width="12%">
        <div class="viewDetails">
          <img src="@/assets/personCenter/caozuo1.png" alt="" />
          <img
            src="@/assets/personCenter/icon_zhiweixiangqing_nor.png"
            alt=""
          />
          <img src="@/assets/personCenter/icon_pinglun_nor.png" alt="" />
        </div>
      </el-table-column> -->
      </el-table>
    </div>
    <div style="text-align: center; margin-top: 20px">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getDict } from "@/api/system/system";
import { jobApplyPage } from '@/api/resumeDelivery/resumeDelivery'
export default {
  name: '',
  data() {
    return {
      tableData: [],
      multipleSelection: '',
      id: '',
      list: [], //简历数组
      total: 1,
      jobPay: [],
    }
  },
  watch: {},
  props: {},
  components: {},
  created() {
    this._getDict();
    this.getJobApplyPage()
  },
  computed: {},
  mounted() {},
  methods: {
    _getDict() {
      getDict({}).then((res) => {
        if (res.code == 200 && res.message === "请求成功") {
          localStorage.setItem("dict", JSON.stringify(res.data));
          this.dict = JSON.parse(localStorage.getItem("dict"));
          this.jobPay = this.dict.filter(
            (i) => i.code == "job_pay"
          )[0].children;
        }
      });
    },
    toDatum(e) {
      this.$router.push({ path: '/datum', query: { id: e.job_post_id } })
    },
    toDatumOrg(e){
      this.$router.push({ path: '/enterpriseDetails', query: { id: e.job_org_id } })
    },
    getPay(code) {
      if(this.jobPay){
        return this.jobPay.filter((i) => i.code == code)[0].name;
      }else{
        return ''
      }
    },
    // 查询投递过的简历
    getJobApplyPage() {
      let obj = {
        jobUserId: localStorage.getItem('userId'),
        pageNo: 1,
        pageSize: 10,
      }
      jobApplyPage(obj).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
        // this.totalNum = res.data.totalPage
        // this.pageSizes = res.data.rainbow
      })
    },
    chengePagination(cur) {
      const obj = {
        jobUserId: localStorage.getItem('userId'),
        pageNo: cur,
        pageSize: 10,
      }
      jobApplyPage(obj).then(res => {
        this.tableData = res.data.rows
        this.total = res.data.totalRows
        // this.totalNum = res.data.totalPage
      })
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    isRemind(e) {
      e.remind = !e.remind
    },
    onSubmit() {},
  },
  filters: {},
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

::v-deep .el-table .el-table__row {
  cursor: pointer;
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

.viewDetails {
  display: flex;
  justify-content: space-between;
  align-content: center;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
</style>
