// 简历分页
import request from "@/api/request"
export function jobApplyPage(data) {
  return request({
    method: 'get',
    url:'/job/jobApply/jobApplyPage',
    params:data
  })
}
//获取当前用户
export function getLoginUser(data) {
  return request({
    method: 'get',
    url:'/jobUser/jobUser/getLoginUser',
    params:data
  })
}